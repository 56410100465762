

/* Global syle code piece
    <span className='break'><br/></span>
 */
 
 @media(max-width:992px){
  .break{
   display:block !important;
   margin-top:-1rem !important
   }
  }

/* Styling Footer content */

.video-container .caption {
  z-index: 1;
  position: relative;
  text-align: center;
  color: #dc0000;
  padding: 10px;
}

.footers{

  background:#E10600FF !important;
  padding-top:5rem !important;
  color:#F2AA4CFF ;
}
.footer2{
  background:#ffffff !important;  
} 
.last-footer{
  background: url(../../public/images/purple14.jpg)center center/cover no-repeat; 
  color: #ECAD7F;
  font-weight:600; 
  max-height:100px !important;
  padding:2rem !important;
  @media(max-width:786px){
  display:flex;
  flex-direction:column; 
  justify-content:center;
  align-items:center;
  padding-bottom: 2rem !important;

  }
}
 /* border: 2px solid #1e154e,
  border-radius:10px; 
  background:ECAD7F;
  color:'#1e154e;
  font-weight:600; */

.media{
position:relative;

;& .socials{
border: 1px solid #ECAD7F;
padding: 1rem;
margin-top: 2rem;
display: flex;
justify-content: center;
align-items: center;
gap:5;
min-width:330px;
max-width:380px;
overflow: hidden;
@media(max-width:850px){
  margin-left:-3rem !important;
}
@media(max-width:765px){
  margin-left:0 !important;
}
;& .footer-link{
margin-left: 1%;
padding: 10px 3rem;
font-size: 2.5rem;
box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
 rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
 rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, 
 rgba(0, 0, 0, 0.06) 0px 2px 1px, 
 rgba(0, 0, 0, 0.09) 0px 4px 2px,
 rgba(0, 0, 0, 0.09) 0px 8px 4px, 
 rgba(0, 0, 0, 0.09) 0px 16px 8px, 
 rgba(0, 0, 0, 0.09) 0px 32px 16px;

;& i{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ECAD7F;
  position: relative;

   }
   @media(max-width:992px){
    padding: 10px 2rem !important;
    font-size: 3rem !important;
     
    }
    @media(max-width:786){
      .footer-link{
        padding: 10px 2.5rem !important;
        font-size: 2rem !important;
        }
         
    
    }

  }
}  
;& h3{
margin-top: 2rem;
}

@media(max-width:430px){
.socials{
  gap:10 !important
}
}
}


.modes>.nextButton,.modes,.modehead, .modet{
background-color: #dc0000 !important;
}


.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border:none !important;
}

.btn-google {
  color: white !important;
  background-color: #ea4335;
  border:none !important;
}

.btn-facebook {
  color: white !important;
  background-color: #3b5998;
}


.reg-btn:hover {
  background-color: dimgray;
  color: white;
}


.minutes{
display:flex;
flex-wrap: wrap;
justify-content: space-between;
}

/*---------MEDIA Querry-------*/
.nextButton {border:none !important}

@media only screen and (max-width: 992px) {
  #section-1 {
    transform: translateY(-5%) !important;
  }
  .section-2-box {
    margin-top: 4rem !important;
  }
}
@media only screen and (max-width: 786px) {
  .header h1 {
    font-size: 14px !important;
    word-spacing: 2px !important;
    letter-spacing: 0;
  }
}

.modes{background: url(../../public/images/bookBG.PNG)center/cover no-repeat;
}
