
/* Navbar styles */
.minutes-page-navbars{
    display: flex  !important; 
    justify-content: space-between !important;
    align-items: center !important;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100% !important;
    min-height: 80px !important;
    background-color: #181123;

/*     background-color: #1e0101;
 */    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 3000;
  
  }
  
  .left-sections{

    padding-left: 4rem;
    span{
        color:#F0E68C;
        font-size:1.1rem !important;
        
    }
  }
  
  .right-sections{
 
    padding-right: 4rem !important;
    span{
        color:#F0E68C;
        font-size:1.1rem !important;
    }
  }

  .button-left,
  .button-right {
      font-weight: 600;
      font-size: 1.3rem !important;
       font-family: Arial, Helvetica, sans-serif;   
       border:none !important; 
       padding: 0px 5px;
       outline:none !important;
      -webkit-border-radius: 10px ;
      border-radius: 10px ;
      -moz-border-radius: 10px ;
      -khtml-border-radius: 10px;
      box-shadow: #1e0101 0px 0px 0px 2px,
      #747373 0px 4px 6px -1px,
      #1e0101 0px 1px 0px inset;
  }
  @media(max-width:786px){
    .left-section{
    min-width: 70vw;
    width:auto;}
    .right-sections{ min-width: 30vw;
      width:auto;} 
    .left-sections,
      .right-sections{
    position:relative;
      .done,
      .reading{
       display:none !important;
    
      .buttons{
        padding: 1%;
      }
    
  }
 
      }
  }
  
  @media(max-width:400px){
  .button-left{
   position:relative;
   left:-2rem !important;
   font-size: 1.1rem !important;
  }
  .button-right{
   position:relative;
   right:-3rem !important;
   font-size: 1.1rem !important;

  }
  }
  @media(max-width:360px){
    .button-left{
     position:relative;
     left:-2rem !important;
    }
    .button-right{
     position:relative;
     right:-3rem !important;
  
    }
    .buttons{
      padding: 0 !important;
    }
    }

 

  
