

.Article-cover{
    max-width:800px !important;
    padding: 6rem 0;
    margin:1rem auto;
   
    p{
        font-size:17px;
        font-family: Arial, sans-serif;   
        line-height: 4.5vh !important;
    }
    h2,h5{
        font-weight:bold;
        color:black !important;
    }
    @media(max-width:786px){
    padding: 6rem 2rem;
    P{
        font-size: 19px; 
        line-height: 4.5vh !important;
    }
    
 

    }
}