
//RESET PASSWORD PAGE HERE
.modal-footer {   border-top: 0px; }


.signForm-cover {
background-color: rgb(9, 1, 1);
min-height:100vh !important;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
padding:2rem 1rem 0;
@media(max-width:992px){
padding:1rem 1rem 0;
}
}
.signIn-card{
    min-width: 80vw !important;
    padding:0 1rem !important;
    
    input{
    padding:1rem 0 !important;
    }
min-height: fit-content !important;
 background: transparent !important;
-webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
background:url(../../public/images/team-bg2.png)center/cover no-repeat !important;

}
.signFormTitle-img{
    transform: translateY(-.2rem) !important;
 h2{
    font-size: 2.5rem !important;
    color: #FFFFFF;
    background: transparent;
    text-shadow: 3px 5px 2px #474747;
 }
}
.ID{
    display:flex;
    gap:3rem !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-wrap: wrap;
}
.form-signUp-btn{
position:relative;
top: -3rem !important;
left: -2rem !important;
color: white !important;
display: flex;
justify-content: space-between;
min-width: 200px !important;
max-width: 300px !important;
.register-component{
    position: relative;
    left:3rem !important;
    top:10px !important;
  
}
.no-account{
    position: relative;
    left:30px !important;
}
}

.form-login-btn{
position:relative;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
p{
   position: relative; 
   top:3rem !important;
   &:hover{
    background: transparent !important;
   }
}

button{
    border: 2px solid #ccc !important; 
    color: white !important;
    padding:0.5rem 2rem;
    border-radius: 10px;
    position:absolute;
   @media(max-width:992px){
    padding:0.6rem 3rem !important;
    font-size: 1.2rem !important;

   }
   

}
.no-account{margin-left:10rem !important}

}
.form-input-body{
position:relative;
top:6rem !important;
input{
    position: relative;
    text-align: center;
  
    &::placeholder{
    position: absolute;
    left:3%;
    }
}
  
}
.signFormTitle-img{
    position:absolute;
    top:1rem
}
.password{
    margin-top:-1.5rem !important;

}  

@media(max-width:992px){
   
    .signFormTitle-img h2{
        font-size: 2rem !important;
    }
  
    .password{
        margin-top:-1.5rem !important;
    }  
    .form-signUp-btn{
        position:relative;
        top: -2rem !important;
        min-width:100vw !important;
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        & .no-account{
            min-width:auto !important;
            font-size: 1.3rem !important;
            top:1.2rem !important;
        }
        & .register-component{
            min-width:auto !important;
            display: block;
            justify-content: flex-start;
            align-items: flex-start;

        }
    }
}

@media(max-width:786px){
    .signFormTitle-img{
        transform: translateY(-2.5rem) !important;
     h2{
        font-size: 1.5rem !important;
      
     }
    }
  
   .form-signUp-btn{
    position:relative;
    top:-2.5rem !important;
    left: -1rem !important;
    
   }
   .no-account{
    font-size: 1rem !important;
    margin-top:10px !important;
    margin-left:-1rem !important
}
   .signIn-card{
    min-width: 100% !important;
    padding: 0 3rem !important;
   }
}
@media(max-width:500px) and (min-width:320px){
   
    .signIn-card{
        margin-top:-6rem !important;
        padding:0 1rem !important;

        .signFormTitle-img{
            transform: translateY(-1rem) !important;
             img{
                min-width:50px !important;
                max-width:50px !important
            
            }
            h2{
                font-size: 1.2rem !important;
                font-weight:800;
                text-transform:uppercase

            }
        }
        input{
        padding:1rem 0 !important;
        }
    }
      
    
    .form-input-body h5{
        font-size: 13px !important;
        text-transform:uppercase
    } 

  

.form-login-btn{
    span a{
        position:absolute;
        font-size: 16px !important;
        left:-13rem !important;
        top:12px !important;
        min-width:600px !important;
        width:auto;
    
         }
}
.form-signUp-btn{
    position:relative;
    top:1.8rem !important;
    left: -1.2rem !important;
    
   }

}
@media(max-width:420px) and (min-width:320px){
  

    .form-login-btn{
        min-width:100% !important;
        max-width:auto !important;
        position: relative;
        left:-2rem !important;
        button{
        padding: 4% 14%;   
        left:2rem !important    
        }
     
      
    }

       .form-input-body{
        position:relative;
        top:6rem !important;
          
          
        }
}