
    
        /*----------------Section Language------------------------------*/


        .languages{min-width:100%;
            min-height:100vh !important;
             height:auto !important;
             padding-bottom: 3rem !important;
             background: url(../../public/images/purple13.jpg)center/cover no-repeat;
             @media(max-width:992px){
            background: rgb(246, 246, 250);
            border-bottom:1px solid #ccc;
             
            }
           
          }
          .language-inner{width:100%; height:auto;}
         
          .head {background:
               url(../../public/images/alidiKids.jpg)50% 42%/cover no-repeat; width:100vw; height:100px;
               display:flex; align-items: flex-start; justify-content:flex-end;
              }
         
          
              .sub-head h3{width:80%;     
               margin-bottom:0.5rem;
               height:100%; 
               line-height: 25px;transform:translatey(-300%);
               font-family: garamond; 
               font-weight: 700;}
               .lead{transform: translate(30%, -45%);
                font-size:13.5px !important;
                color:rgba(250, 250, 6, 0.984) !important;
                letter-spacing: 1px;
                line-height: 12.5px;
              }

          
          .language-container 
          { 
            position: relative;
            width:100vw;
            min-height: 60vh;
            height:auto;
            padding: 2rem 5rem;
           
            .column-66 {
              float: left;
              width: 60%;
              padding: 20px;
              @media(max-width:992px){
                padding-bottom:5rem !important;
               
              }
              }
            
            .column-33 {
              float: left;
              width: 40%;
              padding: 40px;
            
        
            }
            @media(max-width:992px){
             background-color: white !important;
             .column-33,
             .column-66{
             width: 100vw !important;
            }
            .large-font{
              margin-top:-1rem !important;
              font-size: 2rem !important;
              padding-left: 0 !important;
               min-width:100vw !important;
              
             }
            }
            @media(max-width:786px){
              padding: 2rem 0rem;
              .column-66,
              .column-33 {
              width: 100vw !important;
          
            } 
          }
            .button {
              border: none;
              background: #1e1245;
              padding: 14px 28px;
              font-size: 16px;
              cursor: pointer;
              color: #ffffff;
              border-radius: 5px;
              font-weight: 700;
              position:absolute;
              left:5% !important;
          @media(max-width:992px){
              padding: 10px 20px;
              font-size: 1.2rem;
              border-radius: 8px;
              font-weight: 700;
              font-family: Arial, sans-serif;   
              @media(max-width:786px){
                left:4% !important;

              }
            }
            }
  
       }

    .page{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 27px;
      text-align: left !important;
      padding-right: 2rem !important;
      padding-bottom: 5% !important;
      margin: -3% !important;
      font-size: 1rem !important;
      font-family: Arial, sans-serif;   
      line-height: 5vh !important;
      .break{
      display:none;
        }
      @media(max-width:992px){
      width:100% !important;
      font-size: 1.2rem !important;
      padding-left:1rem !important;
      padding-right:0.5rem !important;
      line-height: 4.5vh !important;
      transform: translateY(-1.2rem) !important;
      .break{
       display:block !important;
       margin-top:-1rem !important
       }
      }
      }     
    .lang-row:after {
      content: "";
      display: table;
      clear: both
     }
    iframe{border-radius: 5px;}
    
    .large-font {
      color:#1e1245;
      font-size: 43px;
      padding-bottom: 1rem;
      font-weight:800;
    }
   
      .head .lead {
        z-index: 10;
        color: white;
        height: auto;
        width: 150px;
        font-family: garamond;
        font-size: 12px;
        padding: 10px;

        @media(max-width:786px) {
          display: none;
        }
      }
                
      @media screen and (max-width: 786px) {
        
    }

  #carosel{width:100%; height:100vh}

/*   NativeFood Cards styling contents */

.food-cover{
  margin:auto !important;
  display: grid;
  grid-template-columns: repeat(1, auto);
  justify-items:center;
  align-items: center;
  padding: 2rem !important;
  @media(max-width:992px){
    .significant-items{
    
      padding:3% 0 3% 0!important;
    }
  }

  .card-body-food{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
     rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    padding: 8% 5% !important;
    color:rgb(5, 5, 5);
    max-height:200px !important;
    min-height:200px !important;
    margin-top: -6rem !important;

    @media(max-width:992px){
      padding: 7% 5% 3%!important;
      
      }
      
      @media(max-width:786px){
      margin-top: -7rem !important;
      }
     }
  }

.pammy-img{
  width:100%;
  transform:rotateX(-60deg) !important;
  height:370px;
  background: url(../../public/images/pammy.jpg)35% 45%/cover no-repeat;
}
.Nqwobi-img{
  width:100%;
  height:370px;
  transform:rotateX(-60deg) !important;
  background: url(../../public/images/nqwobi.jpg)35% 45%/cover no-repeat;
}
.abacha-img{
  width:100%;
  transform:rotateX(-60deg) !important;
  height:370px;
  background: url(../../public/images/abacha.jpg)35% 45%/cover no-repeat;
}
.oji-img{
  width:100%;
  transform:rotateX(-60deg) !important;
  height:370px;
  background: url(../../public/images/ojiOse.jpg)35% 45%/cover no-repeat;
}
.pammy-img,
.oji-img,
.abacha-img,
.Nqwobi-img
{
margin-top: -5.4rem !important;
box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
 rgba(0, 0, 0, 0.12) 0px -12px 30px,
  rgba(0, 0, 0, 0.12) 0px 4px 6px,
   rgba(0, 0, 0, 0.17) 0px 12px 13px,
 rgba(0, 0, 0, 0.09) 0px -3px 5px;
 @media(max-width:992px){
  margin-top: -7rem !important;
  }
}
@media(max-width:992px){
  .pammy-img,
  .oji-img,
  .abacha-img,
  .Nqwobi-img{
   height:450px
  }
}




