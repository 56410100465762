/* Logo text styles */
.off-canva{
  background: #181123; 
  background:#0e0221;
  min-height: 100vh !important;
  height:auto;
  }
.nav-cover{
  background-color: #181123 !important;
  min-width:100vw !important;
  min-height: 90px !important;
  max-height: 90px !important;
  overflow: hidden;
  @media(max-width:786px){
    -webkit-filter: brightness(1.2) contrast(1.2)  !important;;
   -moz-filter: brightness(1.2) contrast(1.2)  !important; ;
    filter: brightness(1.8) contrast(1.8) !important;
  }
 
& .brand-logo{
  min-width:450px;
  display:grid;
  grid-template-columns: repeat(3, auto);
  justify-content:flex-start;
  align-items: center;
  max-height:100%;
  gap:5;
  @media(max-width:992px){
   a{
    margin-left: 1rem !important;
    font-weight: bold;
   }
  }
  & .logo-text {
    position: relative;
    font-size: 1rem;
    font-weight: bold;
    color:rgb(247, 245, 245);
    gap:1rem !important;
   & .acheives{
   position:absolute;
   top:1rem !important;
   }

  }

 & .logo {
  font-size: 2vw;
  font-weight: 800;
  line-height: 0.9;
  color: transparent;
  background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),     
  linear-gradient(127deg, rgba(0,255,0,.8), rgba(0,255,0,0) 70.71%),            
  linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%);
  background-clip: text;
  -webkit-background-clip: text;
  position: relative; 
  @media(max-width:786px){
    left: -1rem !important;
  }
  @media(max-width:500px){
    left: -0.5rem !important;
  }
  @media(max-width:350px){
    left: -0.1rem !important;
  }
  &::after{
    content: "";
    position: absolute;
    width:100%;
    top:1.5rem;
    left:0;
    height:1vh;
    background: linear-gradient(to left, #333, #333 50%, #eee 75%, #333 75%);
    border-bottom-right-radius: 100%;  
   

    
  }

  }
}
@media(max-width:1160px){
  .brand-logo{
    margin-left: -2rem !important;
    min-width:500px !important;
    max-width:500px !important;
  }
  
  .nav-cover{
    padding-right: 3rem !important;
  }
  .chevron-down,.buttons-right{
    transform: translateX(-2rem) !important;
  }
  }

 .buttons-right{
  min-width:300px;
  display:grid;
  grid-template-columns: repeat(2, auto);
  justify-content:flex-end;
  align-items: center;
  & .btn1,.btn2{
    border:none !important;
    outline:none !important;
    background-color: red;
    background-image: linear-gradient(45deg, #f3ec78, #af4261);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Arial, sans-serif;
    & span{
      text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      -webkit-text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      -moz-text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }   
 
  }
}
& .navbar-toggle{
  min-width: 100px;
  font-size: 2rem !important;
  .btn-menu{
  display:flex;
  justify-content: center;
  align-items: center;
  gap:5px;
  background: transparent !important;
  border:none !important;
  .btn-icon {
  position: relative;
  top:1px !important;
  font-size: 1rem !important;
    }
    &:focus{
    box-shadow:none;
    outline:none;
    }


@media(max-width:992px){
  position: relative;
  left:3rem !important;
}
  @media(max-width:786px){
    display:none !important;
  }
} 
 & .Bjustify,.chevron-down:hover {
    cursor: pointer;
  &:not(.chevron-down){
    display:none !important;
@media(max-width:786px){
  display:block !important;
  margin-left:2rem;
}
 }

}
}



/* Media queries */
@media (max-width: 992px) {
  .brand-logo{
    max-width: 100px !important;
    min-width: 100px !important;
    margin-left: 1rem !important;
    & .image{
       min-width:300px !important;
       max-width:300px !important;
       min-height:200px !important;
       max-height:200px !important
    }
    .logo-text {
    display: none !important;
    }
    & .logo{
      font-size: 1.8rem !important;
    }
    
  
  }
 

}

@media (max-width: 786px) {
  .update{
    position: relative;
    left:2rem !important;
    @media(max-width:600px){
     display:none !important;
    }
  }
  .buttons-right{
    display: none !important;
  }

}
@media(max-width:500px){
  .brand-logo{
    margin-left: -2rem !important;
    & .logo{
        font-size: 2rem!important;
      &::after{
        top:1.7;


      
      }
    }
  }
  .Bjustify{
    margin-left: 4rem !important;
  }
}
}