.app {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background-color: #181123;
    position:relative;
    padding-top: 4rem;
    transform: translateY(-3.2rem) !important;


  }
  
  .header {
    font-size: 2.2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: #000 !important;
    background-color: red;
    background-image: linear-gradient(45deg, #f3ec78, #af4261);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
  
  .meeting-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    background: transparent !important;
 
  }
  
  .meeting-button {
    display: block;
    padding: 2rem;
    font-size: 1.2rem;
    text-align: center;
    border: 1px solid red;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    text-decoration: none;
    min-width: 300px !important;
    max-width: 300px !important;
    background-color: red !important;
        background-image: linear-gradient(45deg, #f3ec78, #af4261) !important;
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent !important; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent !important;
        @media(max-width:786px){
          min-width:90vw !important;
          max-width:90vw !important;
          color: #fff3b0 !important;
          border: 1px solid #fff3b0 ;
          font-weight: 600;
          background-color: transparent !important;
          background-image:none !important;
          background-clip: border-box !important;
          -webkit-text-fill-color: #fff3b0  !important;
        }

  }
  
  .meeting-button:hover {
    background-color: #023e8a;
    transform: scale(1.05);
  }
  
  @media (min-width: 768px) {
    .meeting-button {
      width: auto;
      max-width: none;
    }
  }
  